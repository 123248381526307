/*
 * @Descripttion: 
 * @version: 
 * @Author: seven
 * @Date: 2019-09-06 16:39:38
 * @LastEditors: seven
 * @LastEditTime: 2019-09-06 16:39:41
 */
import Vue from 'vue';

export default new Vue();