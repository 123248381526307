/*
 * @Descripttion: 
 * @version: 
 * @Author: seven
 * @Date: 2019-09-06 16:39:55
 * @LastEditors: seven
 * @LastEditTime: 2019-09-07 16:14:10
 */
export const ACCEPT_CONFIG = {
    image: ['.png', '.jpg', '.jpeg', '.gif', '.bmp'],
    video: ['.mp4', '.rmvb', '.mkv', '.wmv', '.flv'],
    document: ['.doc', '.docx', '.xls', '.xlsx', '.ppt', '.pptx', '.pdf', '.txt', '.tif', '.tiff'],
    doc:['.doc', '.docx'],
    xls:['.xls', '.xlsx'],
    ppt:['.ppt', '.pptx'],
    mp3:['.mp3','.wav'],
    getAll(){
        return [...this.image, ...this.video, ...this.document]
    },
};