<!--
 * @Descripttion: 
 * @version: 
 * @Author: seven
 * @Date: 2019-09-06 16:31:34
 * @LastEditors  : seven
 * @LastEditTime : 2020-01-15 11:57:17
-->
<template>
    <div id="global-uploader">
        <!-- 上传 -->
        <uploader
                ref="uploader"
                :options="options"
                :autoStart="false"
                @file-added="onFileAdded"
                @file-success="onFileSuccess"
                @file-progress="onFileProgress"
                @file-error="onFileError"
                class="uploader-app">
            <uploader-unsupport></uploader-unsupport>
            <uploader-btn v-if="showattrs" id="global-uploader-btn" :attrs="attrs" ref="uploadBtn">选择文件</uploader-btn>
            

            <uploader-list v-show="panelShow && uploaderOption.showPanel">
                <div class="file-panel" slot-scope="props" :class="{'collapse': collapse}">
                    <div class="file-title">
                        <h2>文件列表</h2>
                        <div class="operate">
                            <el-button @click="fileListShow" type="text" :title="collapse ? '展开':'折叠' ">
                                <i class="iconfont" :class="collapse ? 'inuc-fullscreen': 'inuc-minus-round'">展开</i>
                            </el-button>
                            <el-button @click="close" type="text" title="关闭">
                                <i class="iconfont icon-close">关闭</i>
                            </el-button>
                        </div>
                    </div>

                    <ul class="file-list">
                        <li v-for="file in props.fileList" :key="file.id">
                            <uploader-file :class="'file_' + file.id" ref="files" :file="file" :list="true"></uploader-file>
                        </li>
                        <div class="no-file" v-if="!props.fileList.length"><i class="iconfont icon-empty-file"></i> 暂无待上传文件</div>
                    </ul>
                </div>
            </uploader-list>

        </uploader>
    </div>
</template>

<script>
    /**
     *   全局上传插件
     *   调用方法：Bus.$emit('openUploader', {}) 打开文件选择框，参数为需要传递的额外参数
     *   监听函数：Bus.$on('fileAdded', fn); 文件选择后的回调
     *            Bus.$on('fileSuccess', fn); 文件上传成功的回调
     */
    import {ACCEPT_CONFIG} from '@/plugins/upload/config';
    import Bus from '@/plugins/upload/bus';
    import SparkMD5 from 'spark-md5'; 
    import { uploadFile } from '@/api/file';
    import $ from 'jquery';
    import { setTimeout } from 'timers';
    import util from '@/plugins/utils'
    export default {
        props: {
            //组件常规配置
            uploaderOption: {
                type: Object,
                default: ()=>{
                    return {
                        showPanel: true,
                        singleFile: false,
                        limit: null,
                        uploadId: 'openUploader',
                        testChunks: true
                        // size: null,
                        // width: null,
                        // height: null,
                        // ratio: null
                    }
                }
            }
        },
        data() {
            return {
                options: {
                    target: `${process.env.VUE_APP_IMG_PROFIX}/file/upload`,
                    //target: 'http://localhost:3000/upload',
                    chunkSize: '5242880',
                    forceChunkSize: true,
                    fileParameterName: 'file',
                    maxChunkRetries: 3,
                    testChunks: this.uploaderOption.testChunks,   //是否开启服务器分片校验
                    // 服务器分片校验函数，秒传及断点续传基础
                    checkChunkUploadedByResponse: function (chunk, message) {
                        let objMessage = JSON.parse(message);
                        if(objMessage.code!==200) {
                            Bus.$emit('fileSuccess',objMessage);
                            return true
                        }
                        if(objMessage.data.fileUploadStatus === 2){
                            objMessage.data['FileStatus'] = 2
                            Bus.$emit('fileSuccess',objMessage.data);
                            return true
                        }
                        return (objMessage.data.yesUpdateChunkList || []).indexOf(chunk.offset + 1) >= 0
                        // if (objMessage.skipUpload) {
                        //     return true;
                        // }
                        // return (objMessage.uploaded || []).indexOf(chunk.offset + 1) >= 0
                    },
                    headers: {
                        token: util.cookies.get('token')
                        // Authorization: Ticket.get() && "Bearer " + Ticket.get().access_token
                    },
                    query: {
                        'type': 'asd'
                    }
                },
                attrs: {
                    accept: ''
                },
                panelShow: false,   //选择文件后，展示上传panel
                collapse: false,
                showattrs:false
            }
        },  
        mounted() {
            if(!('uploadId' in this.uploaderOption)) this.uploaderOption.uploadId = 'openUploader'
            Bus.$on(this.uploaderOption.uploadId, query => {
                query.userId = "1321312312312"
                this.params = query || {};
                
                // 图片 视频 音频this.attrs.accept = "image/*"
                if(this.params.id===1) this.$set(this.attrs,'accept',"image/*")
                else if(this.params.id===2) this.attrs.accept = "audio/*"
                else if(this.params.id===3) this.attrs.accept = "video/*"
                else if(this.params.id === 4) this.attrs.accept = ACCEPT_CONFIG.document
                else if(this.params.id === 5) this.attrs.accept = ".apk"
                else if(this.params.id === 6) this.attrs.accept = ".xlsx, .xls"
                else if(this.params.id === 'jpg') this.attrs.accept = ".jpg"
                else this.attrs.accept = ACCEPT_CONFIG.getAll()
                this.showattrs = true
                setTimeout(()=>{
                    if (this.$refs.uploadBtn) {
                        $(`#global-uploader-btn`).click();
                        this.showattrs = false
                    }
                },0)
            });
        },
        computed: {
            //Uploader实例
            uploader() {
                return this.$refs.uploader.uploader;
            }
        },
        methods: {
            changeAccept(str){
            },
            onFileAdded(file) {
                // this.panelShow = true;
                // this.computeMD5(file);
                // Bus.$emit('fileAdded');
                this.checkFile(file)
            },
            onFileProgress(rootFile, file, chunk) {
                // console.log(`上传中 ${file.name}，chunk：${chunk.startByte / 1024 / 1024} ~ ${chunk.endByte / 1024 / 1024}`)
                // console.log(chunk.endByte - chunk.startByte)
            },
            onFileSuccess(rootFile, file, response, chunk) {
                let res = JSON.parse(response);
                if(res.code!==200){
                    this.$message({ message: res.msg, type: 'error' });
                    this.statusSet(file.id, 'failed');
                    return
                }
                // 服务器自定义的错误（即虽返回200，但是是错误的情况），这种错误是Uploader无法拦截的
                if('data' in res){
                    if(res.data.fileUploadStatus === 2){
                         //Bus.$emit('fileSuccess',res);
                        this.statusSet(file.id, 'success');
                        res['FileStatus'] = 1
                        this.$emit('fileSuccess',res);
                    }else{
                        this.$message({ message: res.msg, type: 'error' });
                        this.statusSet(file.id, 'failed');
                        return
                    }
                    
                }
                // if (!res.result) {
                //     this.$message({ message: res.message, type: 'error' });
                //     // 文件状态设为“失败”
                //     this.statusSet(file.id, 'failed');
                //     return
                // }
                // // 如果服务端返回需要合并
                // if (res.needMerge) {
                //     // 文件状态设为“合并中”
                //     this.statusSet(file.id, 'merging');
                //     api.mergeSimpleUpload({
                //         tempName: res.tempName,
                //         fileName: file.name,
                //         ...this.params,
                //     }).then(res => {
                //         // 文件合并成功
                //         Bus.$emit('fileSuccess');
                //         this.statusRemove(file.id);
                //     }).catch(e => {});
                // // 不需要合并
                // } else {
                //     Bus.$emit('fileSuccess');
                //     console.log('上传成功');
                // }
            },
            onFileError(rootFile, file, response, chunk) {
                this.$message({
                    message: '服务异常',
                    type: 'error'
                })
                this.statusSet(file.id, 'failed');
            },
            /**
             * 计算md5，实现断点续传及秒传
             * @param file
             */
            computeMD5(file) {
                let fileReader = new FileReader();
                let time = new Date().getTime();
                let blobSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice;
                let currentChunk = 0;
                const chunkSize = 10 * 1024 * 1000;
                let chunks = Math.ceil(file.size / chunkSize);
                let spark = new SparkMD5.ArrayBuffer();
                // 文件状态设为"计算MD5"
                this.statusSet(file.id, 'md5');
                file.pause();
                loadNext();
                fileReader.onload = (e => {
                    spark.append(e.target.result);
                    if (currentChunk < chunks) {
                        currentChunk++;
                        loadNext();
                        // 实时展示MD5的计算进度
                        this.$nextTick(() => {
                            $(`.myStatus_${file.id}`).text('校验MD5 '+ ((currentChunk/chunks)*100).toFixed(0)+'%')
                        })
                    } else {
                        let md5 = spark.end();
                        this.computeMD5Success(md5, file);
                        // console.log(`MD5计算完毕：${file.name} \nMD5：${md5} \n分片：${chunks} 大小:${file.size} 用时：${new Date().getTime() - time} ms`);
                    }
                });
                fileReader.onerror = function () {
                    this.error(`文件${file.name}读取出错，请检查该文件`)
                    file.cancel();
                };
                function loadNext() {
                    let start = currentChunk * chunkSize;
                    let end = ((start + chunkSize) >= file.size) ? file.size : start + chunkSize;
                    fileReader.readAsArrayBuffer(blobSlice.call(file.file, start, end));
                }
            },
            computeMD5Success(md5, file) {
                // 将自定义参数直接加载uploader实例的opts上
                Object.assign(this.uploader.opts, {
                    query: {
                        ...this.params,
                    }
                })
                // console.log(this.uploader.opts)
                file.uniqueIdentifier = md5;
                file.resume();
                this.statusRemove(file.id);
            },
            fileListShow() {
                let $list = $('#global-uploader .file-list');
                if ($list.is(':visible')) {
                    $list.slideUp();
                    this.collapse = true;
                } else {
                    $list.slideDown();
                    this.collapse = false;
                }
            },
            close() {
                this.uploader.cancel();
                this.panelShow = false;
            },
            /**
             * 新增的自定义的状态: 'md5'、'transcoding'、'failed'
             * @param id
             * @param status
             */
            statusSet(id, status) {
                let statusMap = {
                    md5: {
                        text: '校验MD5',
                        bgc: '#fff'
                    },
                    merging: {
                        text: '合并中',
                        bgc: '#e2eeff'
                    },
                    transcoding: {
                        text: '转码中',
                        bgc: '#e2eeff'
                    },
                    failed: {
                        text: '上传失败',
                        bgc: '#e2eeff'
                    },
                    success: {
                        text: '上传成功',
                        bgc: '#e2eeff'
                    },
                    warning:{
                        text: '上传等待',
                        bgc: '#e2eeff'
                    },
                    paused:{
                        text: '上传暂停',
                        bgc: '#e2eeff'
                    }
                }
                this.$nextTick(() => {
                    $(`<p class="myStatus_${id}"></p>`).appendTo(`.file_${id} .uploader-file-status`).css({
                        'position': 'absolute',
                        'top': '0',
                        'left': '0',
                        'right': '0',
                        'bottom': '0',
                        'zIndex': '1',
                        'backgroundColor': statusMap[status].bgc
                    }).text(statusMap[status].text);
                })
            },
            statusRemove(id) {
                this.$nextTick(() => {
                    $(`.myStatus_${id}`).remove();
                })
            },
            error(msg) {
                this.$notify({
                    title: '错误',
                    message: msg,
                    type: 'error',
                    duration: 2000
                })
            },
            async checkFile(file){
                if(this.uploaderOption.limit){
                    if('size' in this.uploaderOption.limit) 
                        if(!this.checkfileSize(file)) return
                    if('width' in this.uploaderOption.limit || 'height' in this.uploaderOption.limit || 'ratio' in this.uploaderOption.limit){
                        let ischeck = await this.getfileBase64(file)
                        if(!ischeck) return
                    }
                }
                this.panelShow = true;
                this.computeMD5(file);
            },
            getfileBase64(params){
                let _this = this
                return new Promise((resolve, reject) => {
                    var f = params.file;
                    var reader = new FileReader();
                    reader.onload = function (e) {
                        var data = e.target.result;
                        //加载图片获取图片真实宽度和高度
                        var image = new Image();
                        image.onload=function(){
                            var width = image.width;
                            var height = image.height;
                            let data = true
                            if(_this.uploaderOption.limit.width) if(width !== _this.uploaderOption.limit.width) {
                                data = false
                                _this.$message({
                                    message: `请上传图片宽度为${_this.uploaderOption.limit.width},高度为${_this.uploaderOption.limit.height}的文件`,
                                    type: 'warning'
                                });
                            }
                            if(_this.uploaderOption.limit.height) if(height !== _this.uploaderOption.limit.height) {
                                data = false
                                _this.$message({
                                    message: `请上传图片宽度为${_this.uploaderOption.limit.width},高度为${_this.uploaderOption.limit.height}的文件`,
                                    type: 'warning'
                                });
                            }
                            if(_this.uploaderOption.limit.ratio) if(_this.uploaderOption.limit.ratio !== width/height) {
                                data = false
                                _this.$message({
                                    message: `图片比例不对`,
                                    type: 'warning'
                                });
                            }
                            resolve(data)
                        };
                        image.src= data;
                    };
                    reader.readAsDataURL(f);
                })
            },
            checkfileSize(params){
                if(Math.ceil(params.file.size / 1024) > this.uploaderOption.limit.size) {
                    this.$message({
                        message: `请上传文件大小不大于${this.uploaderOption.limit.size}K的文件`,
                        type: 'warning'
                    });
                    return false
                }else return true
            },
        },
        destroyed() {
            Bus.$off(this.uploaderOption.uploadId);
        },
        components: {}
    }
</script>

<style scoped lang="scss">
    #global-uploader {
        position: fixed;
        z-index: 3000;
        right: 20px;
        bottom: 0px;
        .uploader-app {
            width: 520px;
        }
        .file-panel {
            background-color: #fff;
            border: 1px solid #e2e2e2;
            border-radius: 7px 7px 0 0;
            box-shadow: 0 0 10px rgba(0, 0, 0, .2);
            .file-title {
                display: flex;
                height: 40px;
                line-height: 40px;
                padding: 0 15px;
                border-bottom: 1px solid #ddd;
                h2{
                    font-size: 20px;
                }
                .operate {
                    flex: 1;
                    text-align: right;
                    
                    button{
                        font-size: 14px;
                    }
                }
            }
            .file-list {
                position: relative;
                height: 240px;
                overflow-x: hidden;
                overflow-y: auto;
                background-color: #fff;
                > li {
                    background-color: #fff;
                }
            }
            &.collapse {
                .file-title {
                    background-color: #E7ECF2;
                }
            }
        }
        .no-file {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 16px;
        }
        /deep/.uploader-file-icon {
            &:before {
                content: '' !important;
            }
            &[icon=image] {
                background: url(../../assets/images/image-icon.png);
            }
            &[icon=video] {
                background: url(../../assets/images/video-icon.png);
            }
            &[icon=document] {
                background: url(../../assets/images/text-icon.png);
            }
        }
        /deep/.uploader-file-actions > span {
            margin-right: 6px;
        }
    }
    /* 隐藏上传按钮 */
    #global-uploader-btn {
        position: absolute;
        clip: rect(0, 0, 0, 0);
    }
</style>